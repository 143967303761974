import { render, staticRenderFns } from "./ClienteProcessoInput.vue?vue&type=template&id=b0f3036a"
import script from "./ClienteProcessoInput.vue?vue&type=script&lang=js"
export * from "./ClienteProcessoInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports